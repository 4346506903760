// necklace images
import AbaloneShell from './images/Abilone-Shell-Necklace.jpg';
import BronzeBowBeauty from './images/Bronze-Bow-Necklace.jpg';
import DysautonomiaInspiredDragonfly from './images/Dysautonomia-Inspired-Dragonfly-Necklace.jpg';
import MickeyMouseSkeletonKey from './images/Mickey-Mouse-Skeleton-Key-Necklace.jpg';

// earring images
import Amethyst from './images/Amethyst-Drop-Hoop-Earrings.jpg';
import BeachyDangling from './images/Beachy-Turquoise-Dangling-Earrings.jpg';
import DragonFly from './images/Dragonfly-Multi-Colored-Swarovski-Earrings.jpg';
import TealBronze from './images/Earthen-and-Teal-Dangling-Earrings.jpg';
import GreenHoop from './images/Green-Beauty-Hoop-Drop-Earrings.jpg';
import MickeySkeleton from './images/Mickey-Mouse-Skeleton-Key-Earrings.jpg';

// bracelet images
import Beachy from './images/Beachy-Turquoise-Diffuser-Stretch-Bracelet.jpg';
import BlackLava from './images/Black-Lava-Beaded-Diffuser-Stretch-Bracelet.jpg';
import BlueSeashell from './images/Blue-Seashell-Diffuser-Stretch-Bracelet.jpg';
import Burgundy from './images/Burgundy-and-Red-Wrap-Bracelet.jpg';
import Celtic from './images/Celtic-Beauty-Green-Wrap-Bracelet.jpg';
import GreenJeweled from './images/Green-Jeweled-Diffuser-Stretch-Bracelet-with-6-Lava-Beads.jpg';
import Lapis from './images/Lapis-Lazuli-Beaded-Diffuser-Stretch-Bracelet.jpg';
import Turquoise from './images/Large-Turquoise-Beads-Diffuser-Stretch-Bracelet.jpg';
import MetalicBeauty from './images/Metallic-Beauty-Boho-Style-Memory-Wire-Bracelet.jpg';
import MickeyMouseDouble from './images/Mickey-Mouse-Skeleton-Key-Double-Heart-Wrap-Bracelet.jpg';
import MickeyMouseLarge from './images/Mickey-Mouse-Skeleton-Key-Large-Heart-Wrap-Bracelet.jpg';
import Oval from './images/Oval-Turquoise-Beaded-Diffuser-Stretch-Bracelet.jpg';
import PatrioticWire from './images/Patriotic-Wire-Wrapped-Bracelet.jpg';
import PatrioticMemory from './images/Patriotic-Memory-Wire-Bracelet.jpg';
import Watermelon from './images/Watermelon-Cherry-Quartz-Beaded-Diffuser-Stretch-Bracelet.jpg';

// product card objects
  export const abaloneShell = {
    id: 1,
    img: AbaloneShell,
    title: 'SOLD: Abalone Shell',
    desc: '24" Chain with and additional 3.5" extender',
    retail: '$35',
    type: 'necklace',
  };
  export const bronzeBowBeauty = {
    id: 2,
    img: BronzeBowBeauty,
    title: 'Bronze Bow Beauty',
    desc: '20" Handcrafted chain, only one available',
    retail: '$30',
    type: 'necklace',
  };
  export const dysautonomiaInspiredDragonfly = {
    id: 3,
    img: DysautonomiaInspiredDragonfly,
    title: 'Dysautonomia\n Inspired Dragonfly',
    desc: '24" Chain with Sworavski beads, removalable charm',
    retail: '$20',
    type: 'necklace',
  };
  export const mickeyMouseSkeletonKey = {
    id: 4,
    img: MickeyMouseSkeletonKey,
    title: 'Mickey Mouse Skeleton Key',
    desc: '26" Handcrafted chain with 3" extender',
    retail: '$35',
    type: 'necklace',
  };
  export const amethystDrop = {
    id: 5,
    img: Amethyst,
    title: "Amethys Drop Hoop",
    desc: "Description",
    retail: '$12',
    type: 'earring',
  };
  export const beachyDanglingEar = {
    id: 6,
    img: BeachyDangling,
    title: "Beachy Turquoise Dangling",
    desc: "Description",
    retail: '$10',
    type: 'earring',
  };
  export const dragonFlyMulti = {
    id: 7,
    img: DragonFly,
    title: "Dragonfly Multi-Colored Swarovski",
    desc: "Description",
    retail: '$15',
    type: 'earring',
  };
  export const tealBronzeDangle = {
    id: 8,
    img: TealBronze,
    title: "Teal & Bronze Dangling Earrings",
    desc: "Description",
    retail: '$10',
    type: 'earring',
  };
  export const greenHoopBeauty = {
    id: 9,
    img: GreenHoop,
    title: "Green Beauty Hoop Drop",
    desc: "Description",
    retail: '$12',
    type: 'earring',
  };
  export const mickeySkeletonEar = {
    id: 10,
    img: MickeySkeleton,
    title: "Mickey Mouse Skeleton Key",
    desc: "Description",
    retail: '$15',
    type: 'earring',
  };
  export const beachyBrace = {
    id: 11,
    img: Beachy,
    title: 'Beachy Turquoise Diffuser Stretch',
    desc: '8.5" Stretch bracelet w/ seven lava beads',
    retail: '$20',
    type: 'bracelet',
  };
  export const blackLavaBead = {
    id: 12,
    img: BlackLava,
    title: 'Black Lava Beaded Diffuser Stretch',
    desc: '9" Stretch w/ five lava beads',
    retail: '$15',
    type: 'bracelet',
  };
  export const blueSeashellDiffuse = {
    id: 13,
    img: BlueSeashell,
    title: 'Blue Seashell Diffuser Stretch',
    desc: '8" Stretch w/ four lava beads',
    retail: '$15',
    type: 'bracelet',
  };
  export const watermelonCherry = {
    id: 14,
    img: Watermelon,
    title: 'Watermelon Cherry Quartz Beaded Diffuser',
    desc: '8" Stretch w/ six lava beads',
    retail: '$15',
    type: 'bracelet',
  };
  export const burgundyRed = {
    id: 15,
    img: Burgundy,
    title: 'Burgundy and Red Wrap',
    desc: '16" Hand crafted double wrap',
    retail: '$20',
    type: 'bracelet',
  };
  export const celticBeauty = {
    id: 16,
    img: Celtic,
    title: 'Celtic Beauty Green Wrap',
    desc: '16" Hand crafted w/ skeleton key',
    retail: '$25',
    type: 'bracelet',
  };
  export const greenJeweledStretch = {
    id: 17,
    img: GreenJeweled,
    title: 'Green Jeweled Diffuser Stretch',
    desc: '8.5" Stretch w/ six lava beads',
    retail: '$25',
    type: 'bracelet',
  };
  export const lapisStretch = {
    id: 18,
    img: Lapis,
    title: 'Lapis Lazuli Beaded Diffuser Stretch',
    desc: '8" Stretch w/ six lava beads',
    retail: '$15',
    type: 'bracelet',
  };
  export const turquoiseStretch = {
    id: 19,
    img: Turquoise,
    title: 'Turquoise Beads Diffuser Stretch',
    desc: '8" Stretch w/ 3 lava beads',
    retail: '$20',
    type: 'bracelet',
  };
  export const metalicBeautyBoho = {
    id: 20,
    img: MetalicBeauty,
    title: 'Metallic Beauty Boho Style Memory Wire',
    desc: 'Description',
    retail: '$15',
    type: 'bracelet',
  };
  export const mickeyMouseDoubleBrace = {
    id: 21,
    img: MickeyMouseDouble,
    title: 'Mickey Mouse Skeleton Key Double Heart Wrap',
    desc: 'Description',
    retail: '$15',
    type: 'bracelet',
  };
  export const mickeyMouseLargeHeart = {
    id: 22,
    img: MickeyMouseLarge,
    title: 'Mickey Mouse Skeleton Key Large Heart Wrap',
    desc: 'Description',
    retail: '$25',
    type: 'bracelet',
  };
  export const patrioticWireBrace = {
    id: 23,
    img: PatrioticWire,
    title: 'Patriotic Wire Wrap',
    desc: 'Description',
    retail: '$18',
    type: 'bracelet',
  };
  export const ovalHeart = {
    id: 24,
    img: Oval,
    title: 'Oval Turquoise Beaded Diffuser Stretch',
    desc: 'Description',
    retail: '$25',
    type: 'bracelet',
  };
  export const patrioticMemoryBrace = {
    id: 25,
    img: PatrioticMemory,
    title: 'Patriotic Memory Wire',
    desc: 'Description',
    retail: '$15',
    type: 'bracelet',
  };
