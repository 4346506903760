import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <Navbar bg="dark" variant="dark">
      <Nav>
        <Navbar.Brand href="">Pearberries</Navbar.Brand>
        <Nav.Link as={Link} to="/">Home</Nav.Link>
        <Nav.Link as={Link} to="/About">About</Nav.Link>
        <Nav.Link as={Link} to="/Help">Help</Nav.Link>
      </Nav>
    </Navbar>
  )
}

export default Footer;

