import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

function Header() {
  return (
    <Navbar collapseOnSelect expand="sm" fixed="top" bg="dark" variant="dark">
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
      <Nav>
        <Navbar.Brand href="">Pearberries</Navbar.Brand>
        <Nav.Link eventKey="1" as={Link} to="/">Home</Nav.Link>
        <Nav.Link eventKey="2" as={Link} to="/About">About</Nav.Link>
        <Nav.Link eventKey="3" as={Link} to="/Help">Help</Nav.Link>
        <Nav.Link eventKey="4" as={Link} to="/ContactUs">ContactUs</Nav.Link>
      </Nav>
      <Nav className="ml-auto">
          <Nav.Link className="text-white" target="_blank" href="https://www.facebook.com/Pearberries-407798556499802/">
            <FontAwesomeIcon icon={faFacebook} />
          </Nav.Link>
          <Nav.Link className="text-white" target="_blank" href="https://instagram.com/pearberries_by_kimberly/">
            <FontAwesomeIcon icon={faInstagram} />
          </Nav.Link>
      </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header;
