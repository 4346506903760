import React from 'react';
import './card.css';

// base card component
const Card = ({image, title, description, retail}) => {
    return ( 
      <div className="CardContainer">
        <img className="CardImage" src={image} alt="" />
        <h3 className="CardTitle">{title}</h3>
        <p className="CardDescription">{description}</p>
        <p className="CardRetail">{retail}</p>
      </div>
    );
  }

export default Card;
