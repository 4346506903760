import React from 'react';

// Styling
import '../container.css';

// Components

function HelpPage() {
  return (
    <>
    <section className="SectionText">
      <h1 className="HeaderText">Help</h1>
        <h3>Custom Orders</h3>
        <p>Each piece is hand fashioned and crafted, custom orders not listed as available may take up to two weeks for the design and crafting process. Each custom order will be completed in the order it was received. Orders are completed as quickly as possible, however some procedures may take up to a week to cure correctly.</p>

        <p>When doing custom orders that aren’t due to sizing, I only ask style and colors that are preferred. Once designed I will send a photo of the completed piece for your final approval. For example, team or school colors black and green in memory wire wrapped bracelet. Charms can be added but time might be needed to receive them in stock.</p>

        <p>Most of my vintage inspired wrap bracelets are 16-17 inches in length, so if your wrist size is 7-8 it can wrap twice. I can easily take away from the wrap or add an inch if needed, even if it is in my inventory as available now, just email me and let me know your wrist size. Other style sizing will be listed in the photo of the item.</p>

        <h3>Gifting</h3>
        <p>If the order is a gift; delivery address is required and optional special note all for a nominal up charge of only $10.</p>

        <h3>Caring for your jewelry</h3>
        <p>Handcrafted pieces should be worn with care and not exposed to water.</p>

        <h3>Payment</h3>
        <p>All transactions handled using PayPal.</p>

        <h3>Shipping</h3>
        <p>All items are shipped from Nevada and listed in US Dollars. US Domestic Shipping Only: all packages will be shipped via USPS First Class Mail, including delivery confirmation, packages cannot be shipped to a PO Box or outside of the Contiguous United States. Insurance will be added for shipments over $50. Other shipping options available upon request. Contact us via email to discuss. Please wait for confirmation email with updated and revised invoice.</p>

        <p>I’m not responsible for items not received due to an incorrect or an incomplete shipping address. Items marked undeliverable due to an incorrect shipping address will be reissued if and when they are received back and will incur a reshipment fee.</p>

        <p>I am unable to refund or replace stolen packages or for packages that have been confirmed as delivered. Please ensure your delivery address is correct and secure.</p>

        <h3>Returns</h3>
        <p>Any piece bought directly from the website is refundable within 7 days of receipt and must be in original buying condition. Any items that are custom made are not refundable. All shipping fees are non-refundable. Buyer is responsible for return shipment fees.</p>
    </section>
    </>
  );
  }


export default HelpPage;

