import React from 'react';

// styling
// import '../../components/Card/Card.css';
import '../container.css';

// base component
import Card from "../../components/Card/Card";

// product necklaces
import {abaloneShell} from '../../product';
import {bronzeBowBeauty} from '../../product';
import {dysautonomiaInspiredDragonfly} from '../../product';
import {mickeyMouseSkeletonKey} from '../../product';

// product bracelets
import {beachyBrace} from '../../product';
import {blackLavaBead} from '../../product';
import {blueSeashellDiffuse} from '../../product';
import {watermelonCherry} from '../../product';
import {burgundyRed} from '../../product';
import {celticBeauty} from '../../product';
import {greenJeweledStretch} from '../../product';
import {lapisStretch} from '../../product';
import {turquoiseStretch} from '../../product';
import {metalicBeautyBoho} from '../../product';
import {mickeyMouseDoubleBrace} from '../../product';
import {mickeyMouseLargeHeart} from '../../product';
import {patrioticWireBrace} from '../../product';
import {ovalHeart} from '../../product';
import {patrioticMemoryBrace} from '../../product';

// product earrings
import {amethystDrop} from '../../product';
import {beachyDanglingEar} from '../../product';
import {dragonFlyMulti} from '../../product';
import {tealBronzeDangle} from '../../product';
import {greenHoopBeauty} from '../../product';
import {mickeySkeletonEar} from '../../product';

function ProductList() {
  const introduction = "I believe that each one of us is very special and so unique that even our fingerprints are all designed uniquely to each one of us. Most of my handcrafted designs are very limited and will vary slightly from each fashioned piece so that each is as unique as you are! Make yourself at home, come back regularly to see new product lines as they are released, and don't forget to tell your friends!";

    return (
      <>
        <p className="WelcomeText HeaderText">Welcome to Pearberries!</p>
        <p className="PText">{introduction}</p>
        <section>
          <h1 className="PageTitle">Necklaces</h1>
          <div className="flex-container">
          <Card image={abaloneShell.img} title={abaloneShell.title} description={abaloneShell.desc} retail={abaloneShell.retail} />
          <Card image={bronzeBowBeauty.img} title={bronzeBowBeauty.title} description={bronzeBowBeauty.desc} retail={bronzeBowBeauty.retail} />
          <Card image={dysautonomiaInspiredDragonfly.img} title={dysautonomiaInspiredDragonfly.title} description={dysautonomiaInspiredDragonfly.desc} retail={dysautonomiaInspiredDragonfly.retail} />
          <Card image={mickeyMouseSkeletonKey.img} title={mickeyMouseSkeletonKey.title} description={mickeyMouseSkeletonKey.desc} retail={mickeyMouseSkeletonKey.retail} />
          </div>
        </section>
        <section>
          <h1 className="PageTitle">Bracelets</h1>
          <div className="flex-container">
          <Card image={beachyBrace.img} title={beachyBrace.title} description={beachyBrace.desc} retail={beachyBrace.retail} />
          <Card image={blackLavaBead.img} title={blackLavaBead.title} description={blackLavaBead.desc} retail={blackLavaBead.retail} />
          <Card image={blueSeashellDiffuse.img} title={blueSeashellDiffuse.title} description={blueSeashellDiffuse.desc} retail={blueSeashellDiffuse.retail} />
          <Card image={watermelonCherry.img} title={watermelonCherry.title} description={watermelonCherry.desc} retail={watermelonCherry.retail} />
          </div>
          <div className="flex-container">
          <Card image={burgundyRed.img} title={burgundyRed.title} description={burgundyRed.desc} retail={burgundyRed.retail} />
          <Card image={celticBeauty.img} title={celticBeauty.title} description={celticBeauty.desc} retail={celticBeauty.retail} />
          <Card image={greenJeweledStretch.img} title={greenJeweledStretch.title} description={greenJeweledStretch.desc} retail={greenJeweledStretch.retail} />
          <Card image={lapisStretch.img} title={lapisStretch.title} description={lapisStretch.desc} retail={lapisStretch.retail} />
          </div>
          <div className="flex-container">
          <Card image={turquoiseStretch.img} title={turquoiseStretch.title} description={turquoiseStretch.desc} retail={turquoiseStretch.retail} />
          <Card image={metalicBeautyBoho.img} title={metalicBeautyBoho.title} description={metalicBeautyBoho.desc} retail={metalicBeautyBoho.retail} />
          <Card image={mickeyMouseDoubleBrace.img} title={mickeyMouseDoubleBrace.title} description={mickeyMouseDoubleBrace.desc} retail={mickeyMouseDoubleBrace.retail} />
          <Card image={mickeyMouseLargeHeart.img} title={mickeyMouseLargeHeart.title} description={mickeyMouseLargeHeart.desc} retail={mickeyMouseLargeHeart.retail} />
          </div>
          <div className="flex-container">
          <Card image={patrioticWireBrace.img} title={patrioticWireBrace.title} description={patrioticWireBrace.desc} retail={patrioticWireBrace.retail} />
          <Card image={ovalHeart.img} title={ovalHeart.title} description={ovalHeart.desc} retail={ovalHeart.retail} />
          <Card image={patrioticMemoryBrace.img} title={patrioticMemoryBrace.title} description={patrioticMemoryBrace.desc} retail={patrioticMemoryBrace.retail} />
          </div> 
        </section>
        <section>
          <h1 className="PageTitle">Earrings</h1>
          <div className="flex-container">
          <Card image={amethystDrop.img} title= {amethystDrop.title} description={amethystDrop.desc} retail={amethystDrop.retail} />
          <Card image={beachyDanglingEar.img} title= {beachyDanglingEar.title} description={beachyDanglingEar.desc} retail={beachyDanglingEar.retail} />
          <Card image={dragonFlyMulti.img} title= {dragonFlyMulti.title} description={dragonFlyMulti.desc} retail={dragonFlyMulti.retail} />
          <Card image={tealBronzeDangle.img} title= {tealBronzeDangle.title} description={tealBronzeDangle.desc} retail={tealBronzeDangle.retail} />
          </div>
          <div className="flex-container">
          <Card image={greenHoopBeauty.img} title= {greenHoopBeauty.title} description={greenHoopBeauty.desc} retail={greenHoopBeauty.retail} />
          <Card image={mickeySkeletonEar.img} title= {mickeySkeletonEar.title} description={mickeySkeletonEar.desc} retail={mickeySkeletonEar.retail} />
          </div>
        </section>
      </>
    )
  }

export default ProductList;
