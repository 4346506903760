import React from 'react';
import '../container.css';

// Components

function AboutPage() {
  return (
    <>
      <section className="SectionText">
        <h1 className="HeaderText">About Me</h1>
          <p>My mother learned a very unique skill later in her life of jewelry making and wanted to pass it on to me. At first, it honestly didn’t take off but a couple of years after she had passed, I really started creating and designing jewelry. It has now become a passion of mine and I love being able to have such a creative outlet!</p>

          <p>My first pieces were the Story Lockets but that wasn’t designing nor creating anything. I then got inspired to do earrings and really liked that. While shopping one day I got the idea of a Vintage bracelet wrap design adding vintage and antique buttons. They were a HIT! I like to upcycle so no two pieces are exactly alike!</p>
      </section>
    </>
    );
  }


export default AboutPage;

