import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

import TopNav from "./components/TopNav/TopNav";
import MainPage from "./containers/MainPage";
import AboutPage from "./containers/AboutPage";
import HelpPage from "./containers/HelpPage";
import ContactForm from "./containers/ContactForm";
import BottomNav from "./components/BottomNav/BottomNav";

function App() {
  useEffect(() => {
    document.title = 'Pearberries';
  });

  return (
    <Router>
      <TopNav />
        <div className="App">
          <div className="App-container">
            <div>
              <Switch>
                <Route exact path="/" component={MainPage} />
                <Route path="/About" component={AboutPage} />
                <Route path="/Help" component={HelpPage} />
                <Route path="/ContactUs" component={ContactForm} />
              </Switch>
            </div>
          </div>
        </div>
      <BottomNav />
    </Router>
  );
}

export default App;
