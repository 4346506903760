import React from 'react';
import { Route } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import './contact.css';
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
init("user_5hoGJZdQxxGNW7MBIXcVU");


export default function ContactUs() {

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('contactpearberries', 'template_gwv7134', e.target, 'user_5hoGJZdQxxGNW7MBIXcVU')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  function handleSubmit() {
    setTimeout(function(){ window.location.href = "/";}, 1500);
  }


  return (
    <Form className="ContactForm" onSubmit={sendEmail}>
      <Form.Group controlId="formBasicEmail">
        <Form.Label className="ContactLabel">Email Address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" name="user_email" />
        <Form.Text className="contactLabel">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>
      <Form.Group controlId="formBasicText">
        <Form.Label className="ContactLabel">Name</Form.Label>
        <Form.Control type="text" placeholder="Enter name" name="user_name" />
      </Form.Group>
      <Form.Group controlId="formBasicText">
        <Form.Label className="ContactLabel">Message</Form.Label>
        <Form.Control as="textarea" aria-label="With textarea" type="text" placeholder="Message" name="message"/>
      </Form.Group>
      <Route render={({history}) => (
      <Button onClick={handleSubmit} variant="light" type="submit" value="Send">
      Submit
      </Button>
      )} />
    </Form>
  );
}

